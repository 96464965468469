.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px 10px 8px 20px;
    border-radius: 1px;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.08), 0px 25px 30px -15px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
    border-left: 4px solid #0a6eb4;
    color: #031c2d;
}

.wfp--btn:focus {
    background: #85b7da !important;
    color: #000 !important;
  }
  .wfp--content-switcher--sm .wfp--content-switcher-btn {
    color: #777;
  }
  .wfp--content-switcher--sm
    .wfp--content-switcher-btn.wfp--content-switcher--selected {
    color: #fff;
  }
  .wfp--modal-content {
    /*margin-bottom:0px;*/
    /* border:1px solid #aaa;*/
  }
  .alert-box .wfp--modal-content {
    border: none;
  }
  .m-frame-filter-wrap > .select-frame {
    max-height: 3em;
  }
  .m-frame-filter-wrap .wfp--form-item .wfp--label {
    margin-bottom: 0.2rem;
  }
  .wfp--btn{
      font-size:0.7rem;
  }
  .wfp--btn--disabled{
    opacity:0.6;
    background:#ddd;
    border:none;
    color:#777;
  }
  .wfp--table{
    border:1px solid #bfbfbf;
  }