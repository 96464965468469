@import "@wfp/ui/src/globals/scss/styles.scss";
@import "~flexboxgrid2/flexboxgrid2.min.css";
@import "~react-toastify/scss/main.scss";

@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~css-file-icons/build/css-file-icons.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
$d_fontSize: 12px;
$d_colorMain: #269ff2;
$interactive01: #0b77c2;
$interactive02: #085387;
$ui05: #8c9ba5;
body {
  background: $interactive02;
}
span svg {
  vertical-align: middle;
}
.wfp--input-wrapper .multi-select {
  width: 100%;
}
.md-info ul li {
  list-style-type: disc !important;
  margin-left: 20px;
  line-height: 1.5em;
}
.md-info p {
  margin-bottom: 10px;
}

iframe {
  width: 100%;
  height: 100%;
}
.ps-r {
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pr-vm * {
  vertical-align: middle;
}
.s-mob,
.si-mob {
  display: none;
}
.t-mob {
  margin-left: 15px;
}
.sidebar {
  z-index: 1;
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 0;
  width: 205px;
  display: block;
  background: $interactive01;
}

.sidebar li:hover,
.sidebar li.active {
  background: $interactive02 !important;
}
.sidebar li a {
  width: 100%;
  padding: 13px 25px;
  display: block;
  font-size: $d_fontSize;
  text-decoration: none;
  color: #fff;
  display: flex;
}
.nav-icon {
  margin: 3px 10px 0px 0px;
}
.main-wrap {
  width: 100%;
  position: relative;
  background: $ui05;
}
.p-r {
  position: relative;
}
.wfp--main-navigation {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 5;
}

.login-module {
  margin-top: 50px;
}
/*slim-select*/
.ss-main .ss-multi-selected {
  padding: 5px;
  border: 1px solid #bfbfbf;
  border-radius: 0px;
}
.ss-main .ss-single-selected {
  padding: 0.71rem;
  height: auto;
  border: 1px solid #bfbfbf;
  border-radius: 0px;
}
.wfp--checkbox-wrapper:last-child {
  margin-bottom: 0px;
}
.v-title {
  margin-bottom: 10px;
}
.wrap-box {
  border: 1px solid #bfbfbf;
  padding: 10px;
  margin-bottom: 0.5rem;
}
.ss-main .ss-multi-selected:focus,
.ss-main .ss-single-selected:focus {
  border-color: var(--interactive-01);
  outline: 1px solid var(--interactive-01);
  box-shadow: 0px 2px 8px 0 #c2dbec;
}
.ss-main .ss-multi-selected,
.ss-main .ss-single-selected,
.ss-content .ss-list {
  font-size: 0.875rem;
}
.ss-main .ss-multi-selected .ss-values .ss-value {
  border-radius: 0px;
  margin: 0.2em 0.4em 0.2em 0;
  padding: 0.46em;
  background-color: $interactive02;
}
.ss-main .ss-single-selected .ss-values .ss-value {
  border-radius: 0px;
  margin: 0px 2px 0 0;
}
/*slim-select*/
.main.collapsed {
  margin-left: 0px;
  /*transform: translate3d(205px,0, 0) !important;
  width:calc( 100% - 205px) !important;*/
  left: 205px;
  transition: all 0.5s;
}
.main.open {
  margin-left: 0px;
  /* transform: translate3d(0,0, 0) !important;
  width:100% !important;*/
  left: 0px;
  transition: all 0.5s;
}
.sidebar.open {
  -webkit-transform: translate3d(-270px, 0, 0);
  -moz-transform: translate3d(-270px, 0, 0);
  -o-transform: translate3d(-270px, 0, 0);
  -ms-transform: translate3d(-270px, 0, 0);
  transform: translate3d(-270px, 0, 0) !important;
  transition: all 0.5s;
}
.sidebar.collapsed {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0) !important;
  transition: all 0.5s;
}
@media screen and (max-width: 991px) {
  .wfp--modal {
    overflow: scroll;
  }

  .main.collapsed {
    margin-left: 0px;
    /*transform: translate3d(205px,0, 0) !important;
    width:calc( 100% - 205px) !important;*/
    left: 0px;
    transition: all 0.5s;
  }
  .main.open {
    margin-left: 0px;
    /* transform: translate3d(0,0, 0) !important;
    width:100% !important;*/
    left: 0px;
    transition: all 0.5s;
  }
  .login-module {
    margin-top: 0px;
  }
  .lg-sc-only {
    display: none;
  }
  .h-mob {
    display: none;
  }
  .si-mob {
    display: inline;
  }
  .s-mob {
    display: block;
  }
  .wfp--main-navigation__wrapper {
    padding: 0 20px;
  }

  .wfp--module .m-frame-inner-wrap {
    max-height: max-content !important;

    height: auto;
  }
  .wfp--module .wfp--module__content {
    max-height: max-content !important;
    /*height:80vh !important;*/
    /*height:auto;*/
  }
  .normal-module-wrap .wfp--module__content {
    height: auto !important;
  }
}

.wfp--module .wfp--module__content {
  max-height: max-content !important;
  height: 84vh;
  /*height:auto;*/
}

.xform.wfp--module .wfp--module__content,
.normal-module-wrap .wfp--module__content {
  height: auto !important;
}
.lg-sc-only {
  display: "";
}
.wfp--main-navigation__logo-wrapper {
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .wfp--main-navigation__sub--open {
    right: 0px;
    width: 300px;
    left: auto;
  }
  .wfp--module .wfp--module__content {
    max-height: 84vh;
  }
  .md-info .wfp--module .wfp--module__content {
    height: 84vh;
  }
  .wfp--module .m-frame-inner-wrap {
    max-height: 80vh;
    overflow: scroll;
  }
}

.leaflet-container {
  width: 100%;
  height: 100%;
}
/*
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidebar a:hover {
  color: #f1f1f1;
}
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
#main {
  transition: margin-left .5s;
  padding: 16px;
}
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}*/
.col-lg-0 {
  display: none !important;
}

@media screen and (max-width: 992px) {
  /*tablet*/
  .col-lg-0,
  .col-xs-0 {
    display: block !important;
  }
  .flag-img {
    width: 550px;
  }
  .col-md-0 {
    display: none !important;
  }
  .tablet-hide {
    display: none;
  }
  .tablet-f-w {
    width: 100% !important;
  }
  .num-input-wrap {
    position: relative !important;
  }
  .num-input-wrap .num-input-input {
    position: relative !important;
    width: 100%;
    height: auto;
    font-size: 20pt;
  }
}
.phone-only {
  display: none;
}
@media screen and (max-width: 600px) {
  /*phone */
  .phone-hide {
    display: none;
  }
  .phone-only {
    display: block;
  }
  .phone-f-w {
    width: 100% !important;
  }
  .m-frame-inner-wrap {
    overflow: auto;
  }
  .col-lg-0,
  .col-md-0 {
    display: block !important;
  }
  .col-xs-0 {
    display: none !important;
  }
  .inp-row {
    margin-bottom: 20px !important;
    box-shadow: 1px 2px 2px;
  }
}
